import React from "react";
import examplephoto128 from "../images/examplephoto128.png";
import p3dexamplephoto1 from "../images/p3dexamplephoto1.png";
import richherbertlaw from "../images/richherbertlaw.png";
import Project from "./Project";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import "../themes/Projects.css";

const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
});

export default function Projects() {
  var description128 =
    "128 Productions is an events coordination company located in Denver, CO. Written in React.js. Incorporated Contentful CMS for event management and photo galleries. Displays a developed and advanced understanding of React Hooks and headless content management systems.";
  var descriptionp3d =
    "Precision Digital Dental Designs was a Dental startup company that specialized in Surgical Guides and 3D Modeling. Developed in ReactJS on Rails with AWS and Shopify integration. Uses a RESTful Rails API for user data and file management, Shopify for payment, and AWS for file storage.";
  var descriptionrich =
    "Rich Herbert is a criminal defense and housing lawyer in Agawam, MA. This single page website is a simple portfolio with a contact form.";
  return (
    <div>
      <h2 className="projects-title">Past Projects</h2>
      <ThemeProvider theme={darkTheme}>
        <Project
          image={richherbertlaw}
          title="Rich Herbert Law"
          description={descriptionrich}
          link="https://richherbertlaw.vercel.app"
          target="_blank"
        />
        <Project
          image={examplephoto128}
          title="128 Productions"
          description={description128}
          link="https://128productions-jdvdlvvrb-jackmccalley.vercel.app"
          target="_blank"
        />
        <Project
          image={p3dexamplephoto1}
          title="P3D Guides"
          description={descriptionp3d}
          link="https://github.com/JackMcCalley/p3d_frontend_bu"
          target="_blank"
        />
      </ThemeProvider>
    </div>
  );
}
