import React, {useRef} from "react";
import "../themes/Header.css";
import "../themes/Navbar.css";
import "../themes/Projects.css";
import { Container, Row, Col } from "react-bootstrap";
import ButtonAppBar from "./TopBar";
import Projects from './Projects'

const Home = () => {
  const projectRef = useRef();
  function scrollTo(ref) {
    if (!ref.current) return;
    ref.current.scrollIntoView({behavior: "smooth", block: "start"})
  }

  return (
    <div>
      <Container className="container">
        <ButtonAppBar />
        <Row className="Header">
          <Col>
            <Row className="fade-in-text">
              <div className="colorhover">Hello, I'm Jack McCalley.</div>
            </Row>
            <Row className="fade-in-nav">
              <Col>
                <a className="colorhover" onClick={() => scrollTo(projectRef)}>
                  Projects
                </a>
              </Col>
              <Col>
                <a className="colorhover" href="/#">
                  About
                </a>
              </Col>
              <Col>
                <a className="colorhover" href="/#">
                  Code Demos
                </a>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <div ref={projectRef}>
        <Projects/>
      </div>
    </div>
  );
}

export default Home;
